
import { Component, Vue } from "vue-property-decorator";
import {
  dispatchSubmitSignUp,
  dispatchСheckPromocode,
} from "@/store/main/actions";
import { IProductSentencesResponse } from "@/interfaces";

@Component({
  components: {},
})
export default class SignUp extends Vue {
  public email: string = "";
  public is_successfull: boolean = false;
  public password: string = "";
  public promocode: string = "";
  public referral: any = null;
  public formError: boolean = false;
  public emailError: boolean = false;
  public sentencesResponse: IProductSentencesResponse = {
    products: [],
    promocode: null,
  };
  public loadingState: any = {
    submit: false,
    promo: false,
  };
  public cookieCheckbox: boolean = true;
  public agreementCheckbox: boolean = true;
  public policyCheckbox: boolean = true;
  public ofertaCheckbox: boolean = true;

  public mounted() {
    if (this.$router.currentRoute.params.promocode) {
      this.promocode = this.$router.currentRoute.params.promocode;
      this.handleUpdatePromocode();
    }
  }

  public validateData() {
    if (
      !this.cookieCheckbox ||
      !this.agreementCheckbox ||
      !this.policyCheckbox ||
      !this.ofertaCheckbox
    ) {
      this.formError = true;
      this.$toast.error(
        this.$t("You need to agree with all conditions").toString()
      );
      return false;
    }
    if (
      !this.email ||
      (this.email && !/(.+)@(.+){1,}\.(.+){2,}/.test(this.email)) ||
      !this.password ||
      !this.referral
    ) {
      this.formError = true;
    } else {
      this.formError = false;
    }
    return !this.formError;
  }

  public async handleSubmit() {
    if (await this.validateData()) {
      this.loadingState.submit = true;
      let timeoutId: ReturnType<typeof setTimeout> | null = null;

      const responsePromise = dispatchSubmitSignUp(this.$store, {
        email: this.email,
        password: this.password,
        referral: this.referral.id,
        language: navigator.language.slice(0, 2),
        timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      timeoutId = setTimeout(() => {
        this.is_successfull = true;
      }, 3000);

      const response = await responsePromise;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (response.status === 200 || response.status === 201) {
        this.is_successfull = true;
        //this.$router.push('/dashboard');
      } else if (response.status === 400) {
        if (response.data.email) {
          // this.$toast.error(this.$t('Email is already exists').toString());
          this.emailError = true;
        } else {
          this.$toast.error(this.$t("Error").toString());
        }
      } else if (response.status === 504) {
        this.is_successfull = true;
      } else {
        this.$toast.error(this.$t("Error").toString());
      }
      this.loadingState.submit = false;
    }
  }

  public async handleUpdatePromocode() {
    if (!this.promocode) {
      this.referral = null;
      this.loadingState.promo = false;
      return;
    }
    this.referral = await dispatchСheckPromocode(this.$store, {
      referral: this.promocode,
    });
    this.loadingState.promo = false;
  }
}
